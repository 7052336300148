<template>
    <div class="stats" style="height: 100%">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <nav class="navbar navbar-expand-lg">
                <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 0 15px">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>收藏统计</el-breadcrumb-item>
                    <el-breadcrumb-item>药品收藏统计（西药）</el-breadcrumb-item>
                </el-breadcrumb>
                <head-center></head-center>
            </nav>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <form action="#" class="searchbox">
                            <el-date-picker
                                    v-model="datepicker"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0"
                                    size="small"
                                    style="margin-right: 10px" @change="search">
                            </el-date-picker>
                            <!--<input type="text" class="text search-input" placeholder="输入关键词搜索...">-->
                            <el-button type="primary" size="small" @click="search()">搜索</el-button>
                        </form>
                    </div>
                </div>
                <el-table
                        :data="XDrugTableData"
                        stripe
                        style="width: 100%"
                        height="calc(100% - 80px)">
                    <el-table-column prop="entityName" label="药品名称" width="250"></el-table-column>
                    <el-table-column prop="entityAlias" label="药品别名" width="350"></el-table-column>
                    <el-table-column prop="entityCategory" label="药品分类"></el-table-column>
                    <el-table-column prop="collectionNum" label="收藏人次" width="180"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <span class="primary optionBtn" @click="ToDetail(scope.row)">详情</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="XDrugTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <!--    详情    -->
        <el-dialog title="收藏人次详情" :visible.sync="dialogTableVisible">
            <el-table :data="DetailData" height="500" :header-cell-style="{background:'#F5F7FC'}">
                <el-table-column prop="userName" label="员工">
                    <template slot-scope="data">
                        <p>{{data.row.learnName}}</p>
                        <p>{{data.row.phone}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="organizationName" label="所属单位" align="center"></el-table-column>
                <el-table-column prop="departmentName" label="科室"></el-table-column>
                <el-table-column prop="userTitleName" label="职称"></el-table-column>
                <el-table-column prop="userPostName" label="职位"></el-table-column>
            </el-table>
            <el-pagination @current-change="ChangeDetailPage" :page-size="PageSize2" :current-page="PageIndex" :total="DetailTotal" layout="total, prev, pager, next, jumper"></el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    import {Collection} from "../../../components/STATS/Collection";
    export default {
        name: "XDrugCollection",
        data(){
            var collection = new Collection(this.TokenClient, this.Services.Healthy);
            return{
                CollectDomin:collection,
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
                },
                XDrugTableData: [],
                datepicker: '',
                activeName: 'ZDisease',
                statrTime: '',
                endTime: '',
                PageIndex: 1,
                PageTotal: 1,
                PageSize: 10,
                PageSize2: 10,
                XDrugTotal: 1,
                XDrugList:[],
                dialogTableVisible: false,
                DetailData:[],
                DetailTotal: 0,
                type: 'drug',
                entityId: 0,
            }
        },
        mounted() {
            this.LoadXDrugList();
        },
        methods:{
            LoadXDrugList(){
                var _this = this;
                _this.CollectDomin.XDrugBrowseList(_this.startTime, _this.endTime, _this.PageIndex,
                    function (data) {
                        _this.XDrugTableData = data.data.results;
                        _this.PageIndex = data.data.pageIndex;
                        _this.PageTotal = data.data.pageCount;
                        _this.PageSize = data.data.pageSize;
                        _this.XDrugTotal = data.data.dataTotal;
                    },function (err) {
                        console.log(err);
                    }
                )

            },
            search(){
                this.PageIndex = 1;
                this.startTime = this.datepicker ? this.datepicker[0] + ' 00:00:00' : null;
                this.endTime = this.datepicker ? this.datepicker[1] + ' 23:59:59' : null;
                this.LoadXDrugList();
            },
            ChangePage(pageIndex) {
                this.PageIndex = pageIndex;
                this.LoadXDrugList();
            },
            ChangeDetailPage(pageIndex){
                this.PageIndex = pageIndex;
                this.ToDetail();
            },
            ToDetail(item){
                this.dialogTableVisible = true;
                var _this = this;
                _this.CollectDomin.CollectionDetail(_this.type, item.entityKey, _this.startTime, _this.endTime, _this.PageIndex,
                    function (data) {
                        _this.DetailData = data.data.results;
                        _this.PageIndex = data.data.pageIndex;
                        _this.PageTotal = data.data.pageCount;
                        _this.PageSize2 = data.data.pageSize;
                        _this.DetailTotal = data.data.dataTotal;
                    },function (err) {
                        console.log(err)
                    }
                )
            },
        }
    }
</script>

<style scoped>

    .navbar-expand-lg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar {
        position: relative;
        height: 73px;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .header {
        flex: 1;
        text-align: right;
        margin-right: 15px;
    }

    .messages {
        margin-top: 13px;
        margin-right: 20px;
    }

    .messages .el-icon-bell {
        font-size: 26px;
    }
    /*content*/


    .table-top {
        margin-bottom: 15px;
    }

    .iq-search-bar {
        display: inline-block;
        margin-right: 20px;
    }

    .iq-search-bar .searchbox {
        width: 480px;
        position: relative;
    }

    .iq-search-bar .searchbox .search-link {
        position: absolute;
        right: 15px;
        top: 2px;
        font-size: 16px;
    }

    .iq-search-bar .search-input {
        width: 100%;
        padding: 5px 40px 5px 15px;
        border-radius: 10px;
        border: none;
        background: #ceebee;
        line-height: 1.8;
    }

</style>
<style>
    .stats .el-dialog{
        width: 850px;
    }
    .stats .el-dialog .el-table{
        height: 500px;
    }
</style>
